import React, { useState } from 'react';
import { ApolloProvider, useQuery, useMutation, ApolloClient,InMemoryCache } from '@apollo/client';
import gql from 'graphql-tag';
import Modal from 'react-modal';
import './SampleApiComponent.scss';


// Your GraphQL queries and mutations
const GET_PRODUCT_DATA = gql`
  query {
    getProductData {
        _id
        ProductID
        Category
        ProductName
        Price
        AddedDate
    }
  }
`;

const ADD_PRODUCT_DATA = gql`
  mutation($ProductID: Int!, $Category: String!, $ProductName: String!, $Price: Int!) {
    addProductData(ProductID: $ProductID, Category: $Category, ProductName: $ProductName, Price: $Price) {
        _id
        ProductID
        Category
        ProductName
        Price
    }
  }
`;


  
const SampleApiComponent = () => {
    const { loading, error, data, refetch } = useQuery(GET_PRODUCT_DATA);
    const [addProductData] = useMutation(ADD_PRODUCT_DATA);
  
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState({
      ProductID:0,
      Category:'',
      ProductName:'',
      Price:0,
  
    });

  
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: name === 'Price' ? parseInt(value, 10) : name === 'ProductID' ? parseInt(value, 10) : value,
      });
    };
  
    const handleaddProductData = async () => {
        console.log('formData: ',formData)
      await addProductData({ variables: formData });
      closeModal(); // Close the modal after adding data
      refetch(); // Refetch the data to update the table
    };
  
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;
  
    return (
      <div>
        <button onClick={openModal}>Add Test Data</button>
  

        <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Add Sample Data"
        // className="modal-content" // Apply SCSS class for styling
        className={`modal-content ${isModalOpen ? 'modal-content-open' : ''}`}
      
      >
        <h2>Add Sample Product Data</h2>
        <form>
          <label>
          Product ID:
            {/* <input type="text" name="test" value={formData.test} onChange={handleInputChange} /> */}
            <select name="ProductID" value={formData.ProductID} onChange={handleInputChange}>
              <option>Select Product ID</option>
              <option value={999}>999</option>
              <option value={888}>888</option>
              <option value={777}>777</option>
              {/* Add more options as needed */}
            </select>
          </label>
          <br />
          <label>
          Category:
            <select name="Category" value={formData.Category} onChange={handleInputChange}>
              <option>Select Category</option>
              <option value={'Appliances'}>Appliances</option>
              <option value={'Accessories'}>Accessories</option>
              {/* Add more options as needed */}
            </select>
          </label>
          <br />
          <label>
          Price:
            <select name="Price" value={formData.Price} onChange={handleInputChange}>
              <option>Select Price</option>
              <option value={250}>$250</option>
              <option value={99}>$99</option>
              {/* Add more options as needed */}
            </select>
          </label>
          <br />
          <label>
          Product ID:
            {/* <input type="text" name="test" value={formData.test} onChange={handleInputChange} /> */}
            <select name="ProductName" value={formData.ProductName} onChange={handleInputChange}>
              <option>Select Product Name</option>
              <option value={'Coffee'}>Coffee Maker</option>
              <option value={'Sun Glasses'}>Sun Glasses</option>
             
              {/* Add more options as needed */}
            </select>
          </label>
          <br />
          <button type="button" onClick={handleaddProductData}>Submit</button>
        </form>
        <p>You can view the data after added.</p>
      </Modal>
  
        <table>
          <thead>
            <tr>
              <th>Product ID</th>
              <th>Category</th>
              <th>Product Name</th>
              <th>Price</th>
              <th>AddedDate</th>
            </tr>
          </thead>
          <tbody>
            {data.getProductData.map(testData => (
              <tr key={testData.ProductID}>
                <td>{testData.ProductID}</td>
                <td>{testData.Category}</td>
                <td>{testData.ProductName}</td>
                <td>{testData.Price}</td>
                <td>{testData.AddedDate}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };
// const client = new ApolloClient({
//     uri: 'http://localhost:5000/graphql', // Replace with your GraphQL server endpoint
//     cache: new InMemoryCache(),
//   });

  const client = new ApolloClient({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT || '/graphql',
    cache: new InMemoryCache(),
  });

// ApolloProvider setup
const ApolloApp = () => {
  return (
    <ApolloProvider client={client}>
      <SampleApiComponent />
    </ApolloProvider>
  );
};

export default ApolloApp;
